<!-- 服务协议 -->
<template>
  <div>
    <Header />
    <Sidebar />
    <div class="agreement-main">
      <div class="auto">
        <div class="agreement-container">
          <div class="agreement-box">
            <div class="agreement-content">
              <h2>企业服务平台服务协议</h2>
              <p class="spec">
                为了您能更好的使用企业服务平台服务（以下简称：本服务），您应当阅读并遵守《企业服务平台服务协议》（以下简称:本协议）等相关协议、规则。
              </p>
              <strong class="strong" id="achr1">1、总则</strong>
              <p>
                1.1企业服务平台服务（以下简称"本服务"）是由泛亚企业服务平台（以下简称"本公司"）与企业服务平台用户共同缔结的对双方具有约束力的有效契约。本协议由您和本公司签订。在您注册成为企业服务平台用户之前，请您务必谨慎阅读、充分理解各条款内容；一旦您选择"同意"并完成注册，说明您已充分阅读、理解并接受本协议的全部内容，即表示您同意遵循本协议之所有约定。
              </p>
              <p>
                1.2企业服务平台向用户提供本网站上所展示的产品与服务（下称"企业服务平台服务"、"本服务"），并将不断更新服务内容，最新的企业服务平台服务以企业服务平台网站上的相关云服务器产品及服务介绍的页面展示以及向用户实际提供的为准。
              </p>
              <strong class="strong" id="achr2">2、企业服务平台账户</strong>
              <p>
                2.1在使用本服务前，您必须先注册并取得本公司平台提供的企业服务平台会员账户，在完成注册或激活流程时，您必须同意并保证：
              </p>
              <p>
                2.1.1
                您所填写的用户信息是真实、准确、完整、及时的，且保证企业服务平台可以通过您所填写的联系方式与您取得联系。
              </p>
              <p>
                2.1.2您应根据企业服务平台对应服务的条例要求及时提供相应的身份证明等资料，否则企业服务平台有权拒绝向您提供相关服务。
              </p>
              <p>
                2.1.3
                如果您提供的资料或信息包含有不正确、虚假的信息，企业服务平台将有权向您发出询问或要求改正的通知并保留取消您会员资格并随时结束为您提供服务的权利，企业服务平台对此不需承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。
              </p>
              <p>
                2.1.4
                您应对您的企业服务平台会员账户负责，只有您本人可以使用您的企业服务平台会员账户，该账户具有唯一性，不可转让、不可赠与、不可继承。若因您自身原因致使服务器数据、口令、密码等信息丢失或泄漏等，企业服务平台对此不需承担任何责任，您将承担因此产生的任何直接或间接损失及不利后果。
              </p>
              <p>
                2.2您须自行负责对您的企业服务平台账户和密码保密，且须对您在该登录名和密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议、push服务器或新购等）承担责任。您同意：
              </p>
              <p class="pad-l-2em">
                2.2.1如发现任何人未经授权使用您的企业服务平台账户和密码，或发生违反保密规定的任何其他情况，您会立即通知企业服务平台，并授权企业服务平台将该信息同步给企业服务平台网站。
              </p>
              <p class="pad-l-2em">
                2.2.2请确保您在每次登录企业服务平台平台结束时，以正确步骤退出企业服务平台平台。本公司不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。
              </p>
              <p class="pad-l-2em">
                2.2.3若因您提供的信息不完全，虚假等导致企业服务平台无法正确核实您的真实身份或企业服务平台无法正确判断您的需求等，而导致的企业服务平台无法为您进行及时处理，给您带来的损失，您应自行承担。同时，您理解企业服务平台对您的请求采取措施需要合理期限，对于您通知企业服务平台以及企业服务平台根据您的有效通知采取措施之前，由于他人行为已经给您造成的损失，企业服务平台不承担任何责任。
              </p>
              <strong class="strong" id="achr3">3、平台服务使用守则</strong>
              <p>
                为有效保障您使用本平台服务的合法权益，您理解并同意接受以下规则：
              </p>
              <p>
                3.1您通过包括但不限于以下方式向企业服务平台发出的指令，均视为您本人的指令，不可撤回或撤销，您应自行对企业服务平台执行前述指令所产生的任何结果承担责任。
              </p>
              <p class="pad-l-2em">
                3.1.1通过您的企业服务平台账户和密码进行的所有操作（包括但不限于重装系统、服务器重启、
                服务器push、服务器新购、账户提现、发票申请、合同申请等）；
              </p>
              <p class="pad-l-2em">
                3.1.2通过与您的账号绑定的手机号码或邮箱向企业服务平台发送的全部信息；
              </p>
              <p>
                3.2您购买本服务后，仅可供自己使用，不得直接或间接以转让、出售、出租、合作等任何形式，有偿或无偿地将部分或全部的服务提供给他人使用。
              </p>
              <p>
                3.3您应自行对因使用本服务而存储在企业服务平台服务器的各类数据等信息，在本服务之外，采取合理、安全的技术措施，确保其安全性，并对自己的行为（包括但不限于自行安装软件、采取加密措施或进行其他安全措施等）所引起的结果承担全部责任。
              </p>
              <p>
                3.4您了解并同意本公司单方修改服务的相关规则，而无须征得您的同意，服务规则应以您使用服务时的页面提示为准，您同意并遵照服务规则是您使用本服务的前提。
              </p>
              <p>
                3.5在您使用本服务时，本公司有权依照相应的服务收费介绍、订单或相关协议向您收取服务费用。本公司拥有制订及调整服务资费的权利，具体服务资费以您使用本服务时本网站上所列之收费方式公告或您与本公司达成的其他书面协议为准。
              </p>
              <p>
                3.6企业服务平台可能会以手机短信或电子邮件（或电话或QQ等）方式通知您服务进展情况以及提示您进行下一步的操作，但企业服务平台不保证您能够收到或者及时收到该手机短信或电子邮件（或电话或QQ信息等），且不对此承担任何后果。因此，在服务过程中您应当及时登录到企业服务平台网站查看和进行交易操作。因您没有及时查看和对服务状态进行修改或确认或未能提交相关申请而导致的任何纠纷或损失，企业服务平台不负任何责任。
              </p>
              <strong class="strong" id="achr4">4、用户权利和义务</strong>
              <p>
                4.1
                您有权利享受企业服务平台提供的互联网技术和信息服务，并有权利在接受企业服务平台提供的服务时获得企业服务平台的技术支持、咨询等服务，服务内容详见本网站相关产品介绍。
              </p>
              <p>
                4.2
                您保证不会利用技术或其他手段破坏或扰乱本网站及企业服务平台其他客户的网站。
              </p>
              <p>
                4.3
                您应尊重企业服务平台及其他第三方的知识产权和其他合法权利，并保证在发生侵犯上述权益的违法事件时尽力保护企业服务平台及其股东、雇员、合作伙伴等免于因该等事件受到影响或损失；企业服务平台保留您侵犯企业服务平台合法权益时终止向您提供服务并不退还任何款项的权利。
              </p>
              <p>
                4.4
                对由于您向企业服务平台提供的联络方式有误以及您用于接受企业服务平台邮件的电子邮箱安全性、稳定性不佳而导致的一切后果，您应自行承担责任，包括但不限于因您未能及时收到企业服务平台的相关通知而导致的后果和损失。
              </p>
              <p>4.5您保证：</p>
              <p class="pad-l-2em">
                4.5.1
                您使用企业服务平台产品或服务时将遵从国家、地方法律法规、行业惯例和社会公共道德，不会利用企业服务平台提供的服务进行存储、发布、传播如下信息和内容：
              </p>
              <p class="pad-l-4em">
                4.5.1.1 违反国家法律法规政策的任何内容（信息）；
              </p>
              <p class="pad-l-4em">
                4.5.1.2 违反国家规定的政治宣传或新闻信息；
              </p>
              <p class="pad-l-4em">4.5.1.3 涉及国家秘密或安全的信息；</p>
              <p class="pad-l-4em">
                4.5.1.4 封建迷信或淫秽、色情、下流的信息或教唆犯罪的信息；
              </p>
              <p class="pad-l-4em">
                4.5.1.5 博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；
              </p>
              <p class="pad-l-4em">4.5.1.6 妨碍互联网运行安全的信息；</p>
              <p class="pad-l-4em">
                4.5.1.7
                侵害他人合法权益的信息或其他有损于社会秩序、社会治安、公共道德的信息或内容;
              </p>
              <p class="pad-l-4em">
                4.5.1.8
                您同时承诺不得为他人发布上述不符合国家规定或本服务条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等;
              </p>
              <p class="pad-l-4em">
                4.5.1.9
                不应大量占用企业服务平台计算资源（包括但不限于互联网挖矿、刷流量、私服等行为），并给企业服务平台平台或企业服务平台的其他用户的网络、服务器系统、应用等带来严重的、不合理的影响，导致企业服务平台平台产品与服务或者企业服务平台的其他用户的服务器卡机、死机或者用户基于云平台的应用不可访问等；
              </p>
              <p>
                您承认企业服务平台有权在您违反上述约定时有权终止向您提供服务并不予退还任何款项，因您上述行为给企业服务平台造成损失的，您应予赔偿。
                您保证您不会进行任何滥用企业服务平台资源的行为，包括但不仅限于任何与黑客攻击相关的行为、虚拟货币挖矿、网格计算、抢购等。一经发现，帐户将会被禁止，帐户余额也不予退回。
                您保证您不会在网站未经过企业服务平台完成ICP备案的情况下对外开放。
                您保证注册时使用的手机号码、邮箱、QQ长期有效，一旦发现手机号码过期经通知后还未修改的，将视作无效用户，帐户将会被禁止，帐户余额也不予退回。
                您保证您的业务不会遭受大规模DDoS攻击。您承认企业服务平台有权在您遭受攻击时解绑资源公网IP，甚至冻结账户并终止向您提供服务。
              </p>
              <p class="pad-l-2em">
                4.5.2.
                使用企业服务平台产品或服务时，应遵守您与企业服务平台签订的服务条款、企业服务平台在http://i360.cn相关页面上的管理规范、流程、服务说明、价格说明和您确认同意的订购页面的内容，包括但不限于：
              </p>
              <p class="pad-l-4em">
                4.5.2.1.
                您应按时付款或续费，如未按规定及时续费导致的损失由您自行负责；
              </p>
              <p class="pad-l-4em">
                4.5.2.2. 不应出现任何破坏或试图破坏网络安全的行为等；
                如您违反上述保证，企业服务平台除有权根据相关服务条款采取删除信息、中止服务、终止服务的措施，并有权限制您账户如新购产品或服务、续费等部分或全部功能，如因您上述行为给企业服务平台造成损失的，您应予赔偿。
              </p>
              <p>
                4.7
                若您使用的某项服务中包含可下载的企业服务平台软件，则企业服务平台仅授予您非独占性的、不可转让的、非商业运营目的的个人使用许可。除非企业服务平台另有明示或与您另有约定外，您不得复制、修改、发布、出售或出租服务或所含软件的任何部分，也不得进行反向工程或试图提取该软件的源代码。
              </p>
              <strong class="strong" id="achr5"
                >5、企业服务平台权利和义务</strong
              >
              <p>
                5.1
                企业服务平台应根据您选择的服务以及交纳款项的情况向您提供合格的网络技术和信息服务。
              </p>
              <p>
                5.2
                企业服务平台承诺对您资料采取对外保密措施，不向第三方披露您资料，不授权第三方使用您资料，除非：
              </p>
              <p class="pad-l-2em">
                5.2.1
                依据本服务条款或者您与企业服务平台之间其他服务协议、合同、在线条款等规定可以提供；
              </p>
              <p class="pad-l-2em">5.2.2 依据法律法规的规定应当提供；</p>
              <p class="pad-l-2em">
                5.2.3 行政、司法等职权部门要求企业服务平台提供；
              </p>
              <p class="pad-l-2em">5.2.4 您同意企业服务平台向第三方提供；</p>
              <p class="pad-l-2em">
                5.2.5 企业服务平台解决举报事件、提起诉讼而提交的；
              </p>
              <p class="pad-l-2em">
                5.2.6
                企业服务平台为防止严重违法行为或涉嫌犯罪行为发生而采取必要合理行动所必须提交的；
              </p>
              <p class="pad-l-2em">
                5.2.7
                企业服务平台为向您提供产品、服务、信息而向第三方提供的，包括企业服务平台通过第三方的技术及服务向您提供产品、服务、信息的情况。
              </p>
              <p>
                5.3
                企业服务平台有权根据本服务条款的约定，根据您的服务使用情况，自您的账户余额中直接扣除相关服务费用。
              </p>
              <p>
                5.4
                当存在如下情形之一时，企业服务平台有权对您名下账户资金的部分或全部进行冻结：
              </p>
              <p class="pad-l-2em">
                5.4.1 国家法律、法规、政策、法律文书的规定：
              </p>
              <p class="pad-l-2em">5.4.2 国家有权机关要求进行冻结的：</p>
              <p class="pad-l-2em">
                5.4.3
                您使用企业服务平台服务的行为涉嫌违反国家法律法规及行政规定的；
              </p>
              <p class="pad-l-2em">
                5.4.4
                企业服务平台基于单方面合理判断，认为您的账户操作、资金进出等存在异常时；
              </p>
              <p class="pad-l-2em">
                5.4.5 他人向您账户错误汇入资金等导致您可能存在不当得利的：
              </p>
              <p class="pad-l-2em">
                5.4.6 您遭到他人投诉， 且对方已经提供了一定证据的：
              </p>
              <p class="pad-l-2em">
                5.4.7 企业服务平台根据自行合理判断，认为可能产生风险的其他情况。
                企业服务平台按照前述规定对您的账户资金进行冻结后，将通过邮件、站内信、短信或电话等方式通知您按照相应的程序进行申诉。如果您按照本服务条款的要求进行申诉并经企业服务平台审核通过的，企业服务平台将立即解除资金冻结措施；如果您未在规定时间内进行申请，或您的申诉未能通过企业服务平台审核的，则您确认企业服务平台有权长期冻结该资金，或将相关冻结款项按原路退回该笔资金的充值来源账户（包括但不限于银行账户、支付宝账户、微信账户等）。
              </p>
              <p>
                5.5
                您理解并认可，企业服务平台在必要时将会对您使用的服务进行机房迁移。企业服务平台进行上述操作前至少提前7个自然日通知您，由于进行上述操作可能需要修改您相关域名的解析，因此您需在接到企业服务平台通知后按照企业服务平台通知的时间将域名解析修改到新的IP或域名上，否则因此造成网站、应用无法访问的，由您自行负责。
              </p>
              <strong class="strong" id="achr6"
                >6、隐私及其他个人信息的保护</strong
              >
              <p>
                一旦您同意本服务条款或使用本服务，您即同意企业服务平台按照以下条款来使用和披露您的个人信息。
              </p>
              <p>6.1 登录名和密码</p>
              <p class="indent-2em">
                在您注册帐户时，企业服务平台会要求您设置企业服务平台账户登录名和密码来识别您的身份，并设置密码提示问题及其答案，以便在您丢失密码时用以确认您的身份。您仅可通过您设置的密码来使用该账户，如果您泄漏了密码，您可能会丢失您的个人识别信息，并可能导致对您不利的法律后果。该账户和密码因任何原因受到潜在或现实危险时，您应该立即和企业服务平台取得联系，在企业服务平台采取行动前，企业服务平台对此不负任何责任。
              </p>
              <p>6.2 用户注册信息</p>
              <p class="indent-2em">
                您完成账户注册或激活账户时，应向企业服务平台提供您的真实姓名、地址、国籍、电话号码和电子邮件地址，您还可以选择来填写相关附加信息（包括但不限于您公司所在的省份和城市、时区和邮政编码、传真号码、个人主页和您的职务）。为有针对性地向您提供新的服务和机会，您了解并同意企业服务平台及其关联公司或您登录的其他网站将通过您的电子邮件地址或该手机通知您这些信息。
              </p>
              <p>6.3 银行账户或其他支付账户信息息</p>
              <p class="indent-2em">
                若企业服务平台所提供的服务需要您提供您的银行账户信息，在您提供相应信息后，企业服务平台将严格履行相关保密约定。
              </p>
              <p>6.4 登录记录</p>
              <p class="indent-2em">
                为了保障您使用本服务的安全以及不断改进服务质量，企业服务平台将记录并保存您登录和使用本服务的相关信息，但企业服务平台承诺不将此类信息提供给任何第三方（除双方另有约定或法律法规另有规定及企业服务平台关联公司外）。
              </p>
              <p>6.5 广告</p>
              <p class="indent-2em">
                企业服务平台会对企业服务平台用户的身份数据进行综合统计，并出于销售和奖励的需要使用或披露。
              </p>
              <p>6.6 外部链接</p>
              <p class="indent-2em">
                本网站含有到其他网站的链接，但企业服务平台对其他网站的隐私保护措施不负任何责任。企业服务平台可能在任何需要的时候增加商业伙伴或共用品牌的网站。
              </p>
              <p>6.7 安全</p>
              <p class="indent-2em">
                企业服务平台仅按现有技术提供相应的安全措施来使企业服务平台掌握的信息不丢失，不被滥用和变造。这些安全措施包括向其他服务器备份数据和对用户密码加密。尽管有这些安全措施，但企业服务平台不保证这些信息的绝对安全。
              </p>
              <strong class="strong" id="achr7">7、 系统中断或故障</strong>
              <p>
                系统可能因下列状况无法正常运作，使您无法使用各项互联网服务时，企业服务平台不承担损害赔偿责任，该状况包括但不限于：
              </p>
              <p>7.1 企业服务平台在系统停机维护期间。</p>
              <p>7.2 电信设备出现故障不能进行数据传输的。</p>
              <p>
                7.3
                因台风、地震、海啸、洪水、停电、战争、恐怖袭击等不可抗力之因素，造成企业服务平台系统障碍不能执行业务的。
              </p>
              <p>
                7.4
                由于黑客攻击、电信部门技术调整或故障、网站升级、银行方面的问题等原因而造成的服务中断或者延迟。
              </p>
              <strong class="strong" id="achr8">8、 责任范围及责任限制</strong>
              <p>8.1 企业服务平台仅对本服务条款中列明的责任承担范围负责。</p>
              <p>
                8.2
                本服务之合作单位，所提供之服务品质及内容由该合作单位自行负责。
              </p>
              <p>
                8.3
                您了解并同意，因您使用本服务、违反本服务条款或在您的账户下采取的任何行动，而导致的任何第三方索赔，应且仅应由您本人承担。如果由此引起企业服务平台及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并承担由此造成的全部责任。
              </p>
              <p>
                8.4
                在法律允许的情况下，企业服务平台对于与本服务条款有关或由本服务条款引起的任何间接的、惩罚性的、特殊的、派生的损失（包括业务损失、收益损失、利润损失、使用数据、商誉或其他经济利益的损失），不论是如何产生的，也不论是由对本服务条款的违约（包括违反保证）还是由侵权造成的，均不负有任何责任，即使事先已被告知此等损失的可能性。另外即使本服务条款规定的排他性救济没有达到其基本目的，也应排除企业服务平台对上述损失的责任。
              </p>
              <p>
                8.5
                除本服务条款另有规定或企业服务平台与您就某一具体产品及/或服务另有约定外，在任何情况下，您同意企业服务平台对本服务条款所承担的赔偿责任总额不超过向您收取的当次服务费用总额。
              </p>
              <strong class="strong" id="achr9">9、 完整协议</strong>
              <p>
                9.1
                本服务条款由本服务条款与本网站公示的各项规则组成，相关名词可互相引用参照，如有不同理解，以本服务条款为准。
              </p>
              <p>
                9.2 本服务条款的章节标题仅为行文方便而设，不具有法律或合同效力。
              </p>
              <p>
                9.3
                您对本服务条款理解和认同，您即对本服务条款所有组成部分的内容理解并认同，一旦您使用本服务，您和企业服务平台即受本服务条款所有组成部分的约束。
              </p>
              <p>
                9.4
                本服务条款部分内容被有管辖权的法院认定为违法的，不因此影响其他内容的效力。
              </p>
              <strong class="strong" id="achr10">10、商标、知识产权</strong>
              <p class="indent-2em">
                本网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由本公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
              </p>
              <p>
                非经本公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。
              </p>
              <strong class="strong" id="achr11">11、法律适用与管辖</strong>
              <p class="indent-2em">
                本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议产生之争议，均应依照中华人民共和国法律予以处理，并提交本协议签订地有管辖权的人民法院管辖。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {};
  },
};
</script>

<style scoped>
.inner-ul {
  width: 1000px;
  display: block;
}

.text-over-flow {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* 设置行数 */
  -webkit-box-orient: vertical;
}

.navCon-cate-title {
  cursor: pointer;
}
.head-nav {
  border-bottom: 1px solid #d7d7d7;
  z-index: 998;
}
.head-nav:hover {
  background-color: #fff !important;
  border-bottom: 1px solid #d7d7d7 !important;
}
li.nav_li.active {
  border-color: #b60005;
}
.head-nav .new-pop-list {
  background-color: #b60005;
}
.head-nav .new-pop-list a:hover {
  color: #141414;
  background-color: #d7d7d7;
}
.webSiteConfig > .config_content {
  background-color: #b60005;
}
.webSiteConfig > .config_content {
  width: 80px !important;
}
.webSiteConfig > .config_content > div > div {
  color: #fff !important;
}
.head-nav .nav-list .nav:after {
  background-color: #b60005;
}
.head-nav:hover {
  border-bottom-color: #b60005;
}
.top-header:hover {
  border-bottom-color: #b60005 !important;
}
.webSiteConfig > .config_content > div > ul > li {
  background-color: #d7d7d7;
  width: 100%;
}
.webSiteConfig > .config_content > div > ul > li > a.active {
  color: #141414;
}
.head-nav .nav-right p a {
  color: #141414;
}
.head-nav .nav-right p a:hover {
  color: #b60005;
}
.headNav .navCon .navCon-cate .cateMenu .list-item {
  padding: 0 !important;
}
.list-item .inner-ul {
  padding: 10px 10px 20px 10px;
}

@import "../../assets/static/template/Home/Zkeys/PC/Static/css/module/agreement/agreement.css";
</style>
